<template>
<el-upload v-if="isLoadeShow" class="upload" :action="url" name="file" :with-credentials="true" :headers="myHeaders" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :file-list="file" :show-file-list="false" :on-error="onError" :accept="accept" :limit="1">
    <div class="upBox" :style="{width:loadWidth}">
        <span class="fa fa-cloud-upload"></span> {{uploadTipTitle}}
    </div>
</el-upload>
</template>

<script>
import api from '../../http/api'

export default {
    name: 'upImg',
    props: {
        imgUrl: {
            type: String,
            default: ''
        },
        type: {
            type: Number,
            default: null
        },
        importType: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            isLoadeShow: true,
            file: [],
            uploadTipTitle: '',
            accept: '.jpg,.png',
            loadWidth: '158px',
            fileSize: 200,
            lang: {
                en: 'The server responded incorrectly, please try again later',
                zh: '服务器未响应，请稍后重试',
            },
            loadingInstance:null
        }
    },
    created() {
        this.initView()
    },
    computed: {
        url() {
            let types = [2,6,4]; // logo picture video
            if (this.type === 3) {
                let apiUrl = this.importType === 1 ? api.import : api.userImport
                return process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_BASEURL + '/slapi' + apiUrl  : process.env.VUE_APP_BASEURL + apiUrl
            } else if (types.indexOf(this.type) > -1) { 
                return process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_BASEURL + '/slapi' + api.upload  : process.env.VUE_APP_BASEURL + api.upload
         
            } else {
                return process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_BASEURL + '/slapi' + api.upload  : process.env.VUE_APP_BASEURL + api.upload
            }
        },
        langs() {
            return this.$store.state.lang
        },
        myHeaders() {
            return {
                authorization: sessionStorage.crsfToken,
                "Accept-Language": this.langs ? this.langs === 'zh' ? 'zh-CN' : 'en' : localStorage.lang ? localStorage.lang === 'zh' ? 'zh-CN' : 'en' : 'en'
            }
        },
    },
    watch: {
        langs() {
            this.initView()
        }
    },
    methods: {
        initView() {
            switch (this.type) {
                case 1:
                    this.uploadTipTitle = this.$t('admin.uploadImage');
                    break;
                case 2:
                    this.uploadTipTitle = '上传图片';
                    break;
                case 3:
                    this.uploadTipTitle = '选择文件';
                    this.accept = '.xlsx';
                    break;
                case 4:
                    this.uploadTipTitle = this.$t('admin.uploadVideo');
                    this.accept = '.mp4';
                    break;
                case 6:
                    this.uploadTipTitle = this.$t('admin.uploadImage');
                    break;
                default:
                    this.uploadTipTitle = '上传图片';
                    break;
            }

        },
        beforeAvatarUpload(file) {
            let fileSize = file.size / 1024 / 1024,
                isSize;
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isMP4 = file.type === 'video/mp4';
            const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (this.type === 2) {
                let height = 128;
                if (!isJPG && !isPNG) {
                    this.$message.error(`${this.$t('admin.formatError')}`)
                    return false
                }
                const isHeight = new Promise((resolve, reject) => {
                    let _URL = window.URL || window.webkitURL;
                    let img = new Image();
                    let isImg = false;
                    img.onload = function () {
                        isImg = true
                        let valid = img.height <= height
                        valid ? resolve() : reject()
                    }
                    img.src = _URL.createObjectURL(file);
                    setTimeout(() => {
                        if(!isImg) reject()
                    },500)
                    

                }).then(() => {
                    return file
                }, () => {
                    this.$message.error(`${this.$t('admin.logoTip')}`)
                    return Promise.reject();
                })
                return isHeight

            } else if (this.type === 1 || this.type === 5 || this.type === 6) {
                isSize = fileSize < 2
                if (!isJPG && !isPNG) {
                    this.$message.error(`${this.$t('admin.formatError')}`)
                    return false
                }
                if (!isSize) {
                    this.$message.error(`${this.$t('admin.pictureTip')}`)
                    return false
                }
            } else if (this.type === 4) {
                isSize = fileSize < 50
                if (!isMP4) {
                    this.$message.error(`${this.$t('admin.formatError')}`)
                    return isMP4
                }
                if (!isSize) {
                    this.$message.error(`${this.$t('admin.videoTip')}`)
                    return isSize
                }
            } else if (this.type === 3) {
                this.loadingInstance = Loading.service({
                    text: this.importType === 1 ? this.$t('admin.importTipInfo') : this.$t('admin.importUserTipInfo')
                });
                if (!isXLSX) {
                    this.loadingInstance.close();
                    this.$message.error(`${this.$t('admin.formatError')}`)
                    return isXLSX
                }
            }
        },
        handleAvatarSuccess(res, file) {
          
            if (this.type !== 3) {
                if (res.code ===200) {
                    this.$emit('upload', res.data)
                    this.$message({
                        message: this.$t(`${res.message}`),
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
            } else {
                this.loadingInstance.close();
                if (res.code === 200) {
                    this.$emit('upload', res.data)
                    this.$message({
                        message: this.$t(`${res.message}`),
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }
            }
            this.isLoadeShow = false;
            this.$nextTick(() => {
                this.isLoadeShow = true;
            })
        },
        onError(err, file, fileLis) {
            Message.error(this.lang[localStorage.getItem('lang') || 'en'])
        }
    }
}
</script>

<style lang="scss" scoped>
.upload {
    .upBox {
        width: 100%;
        text-align: center;
        font-weight: normal;
        height: 34px;
        line-height: 34px;
        border-radius: 5px;
        border: 1px solid rgba(217, 217, 217, 1);
        color: #626262;
    }

    p {
        font-size: 10px;
        line-height: 20px;
        text-align: left;
        color: #626262;
    }
}
</style>
