import API from "@/store/API/api";
import Axios from "@/store/API/axios";
const HomeCareBeds = {
  // 家庭养老床位 获取人员信息列表
  getBedPersonList({
      state
    },
    params
  ) {
    return Axios.post(`${API.getBedPersonList}`, params)
  },
  // 家庭养老床位 修改或新增人员信息
  addOrSaveBedPerson({
      state
    },
    params
  ) {
    return Axios.post(`${API.addOrSaveBedPerson}`, params)
  },
  // 家庭养老床位 删除人员信息
  deleteBedPerson({
      state
    },
    params
  ) {
    return Axios.get(`${API.deleteBedPerson}/${params.id}`, params)
  },
  // 家庭养老床位 人员信息详情
  detailBedPerson({
      state
    },
    params
  ) {
    return Axios.get(`${API.detailBedPerson}/${params.id}`, params)
  },

  // 家庭养老床位 获取施工单位列表
  getBedCompanyList({
      state
    },
    params
  ) {
    return Axios.post(`${API.getBedCompanyList}`, params)
  },
  // 家庭养老床位 修改或新增施工单位
  addOrSaveBedCompany({
      state
    },
    params
  ) {
    return Axios.post(`${API.addOrSaveBedCompany}`, params)
  },
  // 家庭养老床位 删除施工单位
  deleteBedCompany({
      state
    },
    params
  ) {
    return Axios.get(`${API.deleteBedCompany}/${params.id}`, params)
  },
  // 家庭养老床位 施工单位详情
  detailBedCompany({
      state
    },
    params
  ) {
    return Axios.get(`${API.detailBedCompany}/${params.id}`, params)
  },
}
export default HomeCareBeds