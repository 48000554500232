const routes = {
  path: "/volunteerManagement",
  name: "volunteerManagement",
  meta: {
    title: "志愿者管理平台",
    keepAlive: false
  },
  redirect: {
    name: "volunteerInfo"
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 志愿者档案
    {
      path: "volunteerInfo",
      name: "volunteerInfo",
      component: () => import("@/views/Pages/volunteerManagement/volunteerInfo"),
      meta: {
        title: "志愿者档案",
        keepAlive: false
      }
    },
    {
      path: "volunteerManagement/volunteerAct",
      name: "volunteerAct",
      component: () => import("@/views/Pages/volunteerManagement/volunteerAct"),
      meta: {
        title: "志愿者活动",
        keepAlive: false
      }
    },
  ]
};

export default routes;