import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const smartElderlyPlat = {

  // 数据快报
  getDataFlash({ state }, params) {
    return Axios.post(`${API.getDataFlash}`, params)
  },
  // 智慧养老床位列表
  getBedList({ state }, params) {
    return Axios.post(`${API.getBedList}`, params)
  },
  // 预警信息 - 列表
  getFlashList({ state }, params) {
    return Axios.post(`${API.getFlashList}`, params)
  },
  // 预警信息 - 一键处理
  clickProcessing({ state }, params) {
    return Axios.post(`${API.clickProcessing}`, params)
  },
  // 预警信息 - 列表
  getFlashDetail({ state }, params) {
    return Axios.post(`${API.getFlashDetail + '/' + params}`)
  },
  // 周报
  getWeekReport({ state }, params) {
    return Axios.post(`${API.getWeekReport}`, params)
  },
  // 语音处理
  textToVol({ state }, params) {
    return Axios.post(`${API.textToVol}`, params)
  },

}


export default smartElderlyPlat