import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const mechanismCare = {
  // 机构养老
  getmechanismCareListServe({ state }, params) {
    return Axios.post(`${API.getorgInfoList}`, params)
  },
  addorgInfo({ state }, params) {
    return Axios.post(`${API.addorgInfo}`, params)
  },
  gitpersonnellinfo({ state }, params) {
    return Axios.post(`${API.gitpersonnellinfo}`, params)
  },
  getOrgBedInfo({ state }, params) {
    return Axios.post(`${API.getOrgBedInfo}`, params)
  },
  saveOrgBedInfo({ state }, params) {
    return Axios.post(`${API.saveOrgBedInfo}`, params)
  },
  detailOrgBedInfo({ state }, params) {
    return Axios.get(`${API.detailOrgBedInfo}/${params.id}`, params)
  },
  deleteOrgBedInfo({ state }, params) {
    return Axios.get(`${API.deleteOrgBedInfo}/${params.id}`, params)
  },
  delorgInfo({ state }, params) {
    return Axios.get(`${API.delorgInfo}/${params.id}`)
  },
  // 老年餐
  getmechanismList({ state }, params) {
    return Axios.post(`${API.getmechanismList}`, params)
  },
  addmealOrg({ state }, params) {
    return Axios.post(`${API.addmealOrg}`, params)
  },
  delmealOrg({ state }, params) {
    return Axios.get(`${API.delmealOrg}/${params.id}`)
  },
  mealOrgdetail({ state }, params) {
    return Axios.get(`${API.mealOrgdetail}/${params.id}`)
  },
  getManInfoList({ state }, params) {
    return Axios.post(`${API.getManInfoList}`, params)
  },
  consConsultantPage({ state }, params) {
    return Axios.post(`${API.consConsultantPage}`, params)
  },
  addmealPersonSave({ state }, params) {
    return Axios.post(`${API.addmealPersonSave}`, params)
  },
  delMealPerson({ state }, params) {
    return Axios.get(`${API.delMealPerson}/${params.id}`)
  },
  mealPersonInfo({ state }, params) {
    return Axios.get(`${API.mealPersonInfo}/${params.id}`)
  },
  orgInfoDetail({ state }, params) {
    return Axios.get(`${API.orgInfoDetail}/${params.id}`)
  },
  mealRecord({ state }, params) {
    return Axios.post(`${API.mealRecord}`, params)
  },

  mealOrgPageListInfo({ state }, params) {
    return Axios.post(`${API.mealOrgPageListInfo}`, params)
  },

  addorgStaff({ state }, params) {
    return Axios.post(`${API.addorgStaff}`, params)
  },
  addorgPerson({ state }, params) {
    return Axios.post(`${API.addorgPerson}`, params)
  },
  orgStaffList({ state }, params) {
    return Axios.post(`${API.orgStaffList}`, params)
  },
  orgPersonList({ state }, params) {
    return Axios.post(`${API.orgPersonList}`, params)
  },
  delorgPerson({ state }, params) {
    return Axios.get(`${API.delorgPerson}/${params.id}`)
  },
  addAgedCounselor({ state }, params) {
    return Axios.post(`${API.addAgedCounselor}`, params)
  },

  detailAgedCounselor({ state }, params) {
    return Axios.get(`${API.detailAgedCounselor}/${params.id}`, params)
  },
  agedMarketPage({ state }, params) {
    return Axios.post(`${API.agedMarketPage}`, params)
  },
  agedMarketSave({ state }, params) {
    return Axios.post(`${API.agedMarketSave}`, params)
  },
  //饭卡管理
  getMealCardList({ state }, params) {
    return Axios.post(`${API.getMealCardList}`, params)
  },
  addMealCard({ state }, params) {
    return Axios.post(`${API.addMealCard}`, params)
  },
  changeMealNum({ state }, params) {
    return Axios.post(`${API.changeMealNum}`, params)
  },
  searchPerson({ state }, params) {
    return Axios.post(`${API.searchPerson}`, params)
  },
  destoryCard({ state }, params) {
    return Axios.post(`${API.destoryCard}`, params)
  },
  addTransCard({ state }, params) {
    return Axios.post(`${API.addTransCard}`, params)
  },
  cardTransList({ state }, params) {
    return Axios.post(`${API.cardTransList}`, params)
  },
  //餐卡管理  删除卡
  deleteTrans({ state }, params) {
    return Axios.post(`${API.deleteTrans}`, params)
  },


  // 获取机构列表
  getMktCompanyList({ state }, params) {
    return Axios.post(`${API.getMktCompanyList}`, params)
  },
  // 修改或新增机构
  addOrSaveMktCompany({ state }, params) {
    return Axios.post(`${API.addOrSaveMktCompany}`, params)
  },
  // 删除机构
  deleteMktCompany({ state }, params) {
    return Axios.get(`${API.deleteMktCompany}/${params.id}`, params)
  },
  // 机构详情
  detailMktCompany({ state }, params) {
    return Axios.get(`${API.detailMktCompany}/${params.id}`, params)
  },
  // 修改或新增服务记录
  addOrSaveConsServiceRecord({ state }, params) {
    return Axios.post(`${API.addOrSaveConsServiceRecord}`, params)
  },
  // 删除服务记录
  deleteConsServiceRecord({ state }, params) {
    return Axios.get(`${API.deleteConsServiceRecord}/${params.id}`, params)
  },
  // 服务记录详情
  detailConsServiceRecord({ state }, params) {
    return Axios.get(`${API.detailConsServiceRecord}/${params.id}`, params)
  },
  // 获取服务对象列表
  getConsServiceRecordList({ state }, params) {
    return Axios.post(`${API.getConsServiceRecordList}`, params)
  },

  // 获取核心服务列表
  getMktCoreSeriveList({ state }, params) {
    return Axios.post(`${API.getMktCoreSeriveList}`, params)
  },
  // 修改或新增核心服务
  addOrSaveMtkCoreSerive({ state }, params) {
    return Axios.post(`${API.addOrSaveMtkCoreSerive}`, params)
  },
  // 删除核心服务
  deleteMtkCoreService({ state }, params) {
    return Axios.get(`${API.deleteMtkCoreService}/${params.id}`, params)
  },
  // 核心服务详情
  detailMktCoreService({ state }, params) {
    return Axios.get(`${API.detailMktCoreService}/${params.id}`, params)
  },

  //老年餐 数据管理 自动生成数据
  //查询
  queryDataTask({ state }, params) {
    return Axios.post(`${API.queryDataTask}`, params)
  },
  //创建
  addDataTask({ state }, params) {
    return Axios.post(`${API.addDataTask}`, params)
  },
  //关闭
  closeDataTask({ state }, params) {
    return Axios.post(`${API.closeDataTask}`, params)
  },
}

export default mechanismCare
