import API from '@/store/API/api'
import Axios from '@/store/API/axios'

const AssessManage = {
  // 获取居家养老-数据统计-人员信息统计
  initElderData({ state }, params) {
    return Axios.get(`${API.initElderData}`)
  },
  // 获取居家养老-数据统计-企业信息统计
  initServiceCompanyData({ state }, params) {
    return Axios.get(`${API.initServiceCompanyData}`)
  },
  // 获取居家养老-人员管理-人员管理列表
  getHomePersonListServe({ state }, params) {
    return Axios.post(`${API.getHomePersonList}`, params)
  },

  // 人员评估列表
  assessList({ state }, params) {
    return Axios.post(`${API.assessList}`, params)
  },

  assessListDetail({ state }, params) {
    return Axios.get(`${API.assessListDetail}/${params.id}`)
  },
  //人员新增
  addAssess({ state }, params) {
    return Axios.post(`${API.addAssess}`, params)
  },

  //服务报告新增
  addreport({ state }, params) {
    return Axios.post(`${API.addreport}`, params)
  },
  //服务报告列表
  reportList({ state }, params) {
    return Axios.post(`${API.reportList}`, params)
  },
   //服务报告详情
   reportDetail({ state }, params) {
    return Axios.post(`${API.reportDetail}/${params}`, params)
  },
  //服务报告删除
  deletereport({ state }, params) {
  return Axios.post(`${API.deletereport}/${params}`, params)
},
//服务报告修改
updatereport({ state }, params) {
  return Axios.post(`${API.updatereport}`, params)
},
  

}

export default AssessManage
