const routes = {
  path: "/smartElderlyPlat",
  name: "smartElderlyPlat",
  meta: {
    title: "智能看护管理平台",
    keepAlive: false
  },
  redirect: {
    name: "smartElderlyPlat"
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 志愿者档案
    {
      path: "dataFlash",
      name: "dataFlash",
      component: () => import("@/views/Pages/smartElderlyPlat/dataFlash"),
      meta: {
        title: "数据快报",
        keepAlive: false
      }
    },
    {
      path: "smartElderly",
      name: "smartElderly",
      component: () => import("@/views/Pages/smartElderlyPlat/smartElderly"),
      meta: {
        title: "智能看护",
        keepAlive: false
      }
    },
    {
      path: "weekReport",
      name: "weekReport",
      component: () => import("@/views/Pages/smartElderlyPlat/weekReport"),
      meta: {
        title: "周报",
        keepAlive: false
      }
    },
  ]
};

export default routes;
