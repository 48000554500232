const routes = {
  path: "/agedCounselor",
  name: "agedCounselor",
  meta: {
    title: "机构养老",
    keepAlive: false
  },
  redirect: {
    name: "agedCounselor"
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    // 人员管理
    {
      path: "agedCounselor/personnelManage/personnelManage",
      name: "agedCounselorPersonnelManage",
      component: () => import("@/views/Pages/agedCounselor/personnelManage/personnelManage/list"),
      meta: {
        title: "人员管理",
        keepAlive: false
      }
    },
    // {
    //   path: " agedCounselor/personnelManage/personnelManage/:type/:id?",
    //   name: "addAgedCounselor",
    //   component: () => import("@/views/Pages/agedCounselor/personnelManage/personnelManage/addOrEdit"),
    //   meta: {
    //     title: "添加人员管理",
    //     keepAlive: false
    //   }
    // },
         {
        path: "agedCounselor/personnelManage/personnelManage/:type/:id?",
        name: "agedCounseloraddPersonnelManage",
        component: () => import("@/views/Pages/agedCounselor/personnelManage/personnelManage/addOrEdit"),
        meta: {
          title: "操作人员管理",
          keepAlive: false
        }
      },
    {
      path: "agedCounselor/personnelManage/serveRecord",
      name: "personnelManageServeRecord",
      component: () => import("@/views/Pages/agedCounselor/personnelManage/serveRecord/list"),
      meta: {
        title: "服务记录",
        keepAlive: false
      }
    },
    // {
    //   path: "agedCounselor/personnelManage/serveRecord/:type/:id?",
    //   name: "addPersonnelManage",
    //   component: () => import("@/views/Pages/agedCounselor/personnelManage/serveRecord/addOrEdit"),
    //   meta: {
    //     title: "操作服务记录",
    //     keepAlive: false
    //   }
    // },
    // 服务记录
    {
      path: "agedCounselor/serveRecord",
      name: "agedCounselorServeRecord",
      component: () => import("@/views/Pages/agedCounselor/serveRecord/"),
      meta: {
        title: "服务记录",
        keepAlive: false
      }
    },
    {
      path: "agedCounselor/AddServeRecord/:type/:id?",
      name: "agedCounselorAddServeRecord",
      component: () => import("@/views/Pages/agedCounselor/serveRecord/addOrEdit"),
      meta: {
        title: "服务记录",
        keepAlive: false
      }
    },
    // {
    //   path: "agedCounselor/personnelManage/personnelManage/:type/:id?",
    //   name: "addPersonnelManage",
    //   component: () => import("@/views/Pages/agedCounselor/personnelManage/personnelManage/addOrEdit"),
    //   meta: {
    //     title: "机构管理",
    //     keepAlive: false
    //   }
    // },
 
  ]
};

export default routes;