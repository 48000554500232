import Vue from "vue";
import VueI18n from "vue-i18n";

// import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
// import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang
// import elementZhTWLocale from "element-ui/lib/locale/lang/zh-TW"; // element-ui lang
import zhCN from "vxe-table/lib/locale/lang/zh-CN";
import enUS from "vxe-table/lib/locale/lang/en-US";

Vue.use(VueI18n);

const messages = {
  "en-US": {
    ...enUS
  },
  "zh-CN": {
    ...zhCN
  }
};
let lang = (sessionStorage.locale =
  sessionStorage.locale ||
  navigator.userLanguage ||
  navigator.language ||
  "en-US");

const i18n = new VueI18n({
  locale: lang, // 语言标识
  //fallbackLocale: "zh-CN", //没有英文的时候默认中文语言
  messages,
  silentFallbackWarn: true //抑制警告
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
});

//  // 异步加载语言包
//  function loadLanguageAsync(lang) {
//    import(`@/i18n/langs/${lang}`).then(msgs => {
//      i18n.setLocaleMessage(lang, msgs.default)
//      i18n.locale = lang
//      return lang
//    })
//  }
//  // 在手动切换语言时调用此方法，页面初始化时需加载一个默认语言，在APP.vue执行一次
//  Vue.prototype.$loadLanguageAsync = loadLanguageAsync

export default i18n;
