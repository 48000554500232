const routes = {
    path: "/assessManage",
    name: "AssessManage",
    meta: {
      title: "评估管理",
      keepAlive: false
    },
    redirect: {
      name: "assessManage"
    },
    component: () => import('@/views/Layout/Main.vue'),
    children: [
      // 人员管理
      {
        path: "personAssess",
        name: "personAssess",
        component: () => import("@/views/Pages/assessManage/personAssess/index"),
        meta: {
          title: "人员评估",
          keepAlive: false
        }
      },
      {
        path: "personAssess/detail",
        name: "personAssessDetail",
        component: () => import("@/views/Pages/assessManage/personAssess/personAssessDetail"),
        meta: {
          title: "人员评估详情",
          keepAlive: false
        }
      },
      {
        path: "personRecord",
        name: "personRecord",
        component: () => import("@/views/Pages/assessManage/personRecord/index"),
        meta: {
          title: "养老报告",
          keepAlive: false
        }
      },
      {
        path: "personRecord/detail",
        name: "personRecordDetail",
        component: () => import("@/views/Pages/assessManage/personRecord/personRecordDetail"),
        meta: {
          title: "养老报告",
          keepAlive: false
        }
      },
      {
        path: "oldChange",
        name: "oldChange",
        component: () => import("@/views/Pages/assessManage/oldchange/index"),
        meta: {
          title: "适老化改造",
          keepAlive: false
        }
      },
      {
        path: "oldChange/detail",
        name: "oldChangeDetail",
        component: () => import("@/views/Pages/assessManage/oldchange/oldchangeDetail"),
        meta: {
          title: "适老化改造",
          keepAlive: false
        }
      },
      
      
    ]
  };
  
  export default routes;