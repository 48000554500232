import Vue from 'vue'
import VueRouter from 'vue-router'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


//获取原型对象上的replace函数
const originalReplace = VueRouter.prototype.replace;
//修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import HomeCare from './Modules/HomeCare'; // 居家养老
import InstitutionalCension from './Modules/InstitutionalCension'; // 机构养老
import agedEat from './Modules/agedEat'; // 老年餐
import agedCounselor from './Modules/agedCounselor'; // 养老顾问
import agedMarket from './Modules/agedMarket'; // 养老早市
import nursingHome from './Modules/nursingHome'; // 社区养老
import adaptationToAging from './Modules/adaptationToAging'; //适老化改造
import homeCareBeds from './Modules/homeCareBeds'; // 家庭养老床位
import volunteerManagement from './Modules/volunteerManagement'; // 志愿者服务
import assessManage from './Modules/assessManage'; // 志愿者服务
import smartElderlyPlat from "./Modules/smartElderlyPlat"; // 智慧养老床位管理平台




import System from './Modules/System'; // 系统管理

const routes = [{
    path: '/',
    redirect: {
      name: "Login"
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Pages/Login/login.vue')
  },
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import('@/views/Pages/myHome/index.vue')
  },
  {
    path: '/layout',
    name: 'Layout',
    redirect: {
      name: "HomePage"
    },
    component: () => import('@/views/Layout/Layout.vue'),
    children: [
      // TaskManage,
      HomeCare,
      InstitutionalCension,
      System,
      agedEat,
      agedCounselor,
      agedMarket,
      nursingHome,
      adaptationToAging,
      homeCareBeds,
      volunteerManagement,
      assessManage,
      smartElderlyPlat
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/Pages/404/404.vue')
  }
]

const router = new VueRouter({
  routes
});




// 全局路由前置守卫
router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  // 获取本地存储的userToken  let token = localStorage.token;
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  const token = sessionStorage.token;
  // 是否登录 
  if (to.name !== 'Login' && !token) {
    next({
      name: 'Login'
    })
  } else {
    // let resources = [];
    // if (sessionStorage.userInfo) {
    //   resources = JSON.parse(sessionStorage.userInfo).resources.split(",");
    // }

    // const nextRoute = ['/', 'Login', 'Layout', '404', ...resources];
    // // 未登录状态；当路由到nextRoute指定页时，跳转至login 
    // if (nextRoute.indexOf(to.name) >= 0) {
    //   next()
    // } else {
    //   router.push({
    //     name: '404'
    //   })
    // }
    next()
  }
});





export default router