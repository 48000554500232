import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import components from './components'
import qs from 'qs'
import tools from './utils/tools'
//引入正则
import regex from './utils/regex'
import dayjs from 'dayjs'
import echarts from 'echarts'
import _ from 'lodash'
import './utils/rem'

import "./assets/css/animate.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/base.scss";
import "./assets/css/common.css";
//引入i18n 国际化资源
import i18n from "./i18n";
import BaiduMap from 'vue-baidu-map'
//引入VXETable 表格组件

import VXETable from "./vxe-table";
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'

VXETable.use(VXETablePluginExportXLSX)
//动态获取国际化资源
Vue.use(VXETable, {
  // 对组件内置的提示语进行国际化翻译
  i18n: key => i18n.t(key)
})

// 全局注册自有组件
Object.keys(components).forEach((key) => {
  var name = key.replace(/(\w)/, (v) => v.toUpperCase())
  Vue.component(`v${name}`, components[key])
})
import VCharts from 'v-charts'

Vue.use(VCharts)


import HighchartsVue from 'highcharts-vue'

Vue.use(HighchartsVue)


Vue.prototype.$getPermission = function (role) {
  let roles = sessionStorage.roles;
  let flag = false;
  if (roles.indexOf(role) > -1) {
    flag = true;
  }
  return flag;
}

Vue.prototype.$qs = qs
Vue.prototype.$tools = tools
Vue.prototype.$regex = regex
Vue.prototype.$dayjs = dayjs
Vue.prototype.$echarts = echarts
Vue.prototype._ = _
Vue.config.productionTip = false
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  // ak: 'Pfg6uyIQY0IONF5uctGi06oP2apNtttQ',
  ak: 'RoGlFABdgiHGHXwB9ioMEhAXgkMuAAlt',
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')