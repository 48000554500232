const routes = {
    path: "/nursingHome",
    name: "nursingHome",
    meta: {
      title: "社区养老",
      keepAlive: false
    },
    redirect: {
      name: "nursingHome"
    },
    component: () => import('@/views/Layout/Main.vue'),
    children: [
      {
        path: "nursingHome/practitioners",
        name: "Practitioners",
        component: () => import("@/views/Pages/nursingHome/practitioners"),
        meta: {
          title: "从业人员",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addPractitioners/:type/:id?",
        name: "AddPractitioners",
        component: () => import("@/views/Pages/nursingHome/practitioners/addOrEdit"),
        meta: {
          title: "从业人员",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/practitioners/detail/:id?",
        name: "PractitionersDetail",
        component: () => import("@/views/Pages/nursingHome/practitioners/detail"),
        meta: {
          title: "从业人员",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/mechaismManage/serviceStation",
        name: "ServiceStation",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/serviceStation"),
        meta: {
          title: "服务站",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addServiceStation/:type/:id?",
        name: "AddServiceStation",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/serviceStation/addOrEdit"),
        meta: {
          title: "服务站",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/serviceStation/detail/:id?",
        name: "ServiceStationDetail",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/serviceStation/detail"),
        meta: {
          title: "服务站",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/mechaismManage/sumCenter",
        name: "SumCenter",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/sumCenter"),
        meta: {
          title: "日照中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addSumCenter/:type/:id?",
        name: "AddSumCenter",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/sumCenter/addOrEdit"),
        meta: {
          title: "日照中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/sumCenter/detail/:id?",
        name: "SumCenterDetail",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/sumCenter/detail"),
        meta: {
          title: "日照中心",
          keepAlive: false
        }
      },    {
        path: "nursingHome/mechaismManage/sumCenter/moniter",
        name: "moniter",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/sumCenter/components/moniter"),
        meta: {
          title: "日照中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/mechaismManage/generalServiceCenter",
        name: "GeneralServiceCenter",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/generalServiceCenter"),
        meta: {
          title: "综合养老服务中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addGeneralServiceCenter/:type/:id?",
        name: "AddGeneralServiceCenter",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/generalServiceCenter/addOrEdit"),
        meta: {
          title: "综合养老服务中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/generalServiceCenter/detail/:id?",
        name: "GeneralServiceCenterDetail",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/generalServiceCenter/detail"),
        meta: {
          title: "综合养老服务中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/medicalInst",
        name: "MedicalInst",
        component: () => import("@/views/Pages/nursingHome/medicalInst"),
        meta: {
          title: "社区医疗机构",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addMedicalInst/:type/:id?",
        name: "AddMedicalInst",
        component: () => import("@/views/Pages/nursingHome/medicalInst/addOrEdit"),
        meta: {
          title: "社区医疗机构",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addCnCoreSerive/:type/:id?",
        name: "AddCnCoreSerive",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/cnCoreSerive/addOrEdit"),
        meta: {
          title: "核心服务",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/addServiceRecord/:type/:id?",
        name: "AddServiceRecord",
        component: () => import("@/views/Pages/nursingHome/mechaismManage/serviceRecord/addOrEdit"),
        meta: {
          title: "核心服务",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/dataCenter",
        name: "NursingHomeDataCenter",
        component: () => import("@/views/Pages/nursingHome/dataCenter"),
        meta: {
          title: "数据中心",
          keepAlive: false
        }
      },
      {
        path: "nursingHome/callCenter",
        name: "CallCenter",
        component: () => import("@/views/Pages/nursingHome/callCenter"),
        meta: {
          title: "呼叫中心",
          keepAlive: false
        },
        redirect: {
          name: "callRecords"
        },
        children: [{
            path: "callRecords",
            name: "callRecords",
            component: () => import("@/views/Pages/nursingHome/callCenter/records"),
            meta: {
              title: "通话记录",
              keepAlive: false
            }
          },
          {
            path: "serviceCall",
            name: "serviceCall",
            component: () => import("@/views/Pages/nursingHome/callCenter/service"),
            meta: {
              title: "未接电话记录",
              keepAlive: false
            }
          },
          {
            path: "missCall",
            name: "missCall",
            component: () => import("@/views/Pages/nursingHome/callCenter/miss"),
            meta: {
              title: "未接电话记录",
              keepAlive: false
            }
          },
          {
            path: "callTable",
            name: "callTable",
            component: () => import("@/views/Pages/nursingHome/callCenter/table"),
            meta: {
              title: "呼叫报表",
              keepAlive: false
            }
          },
          {
            path: "serveRecords/:type/:id?",
            name: "AddCleaningRecords",
            component: () => import("@/views/Pages/nursingHome/mechaismManage/cleaningRecord/componets/addOrEdit"),
            meta: {
              title: "打扫卫生记录",
              keepAlive: false
            }
          },
          {
            path: "serveRecords/:type/:id?",
            name: "AddSisinfectRecords",
            component: () => import("@/views/Pages/nursingHome/mechaismManage/assetsRecord/componets/addOrEdit"),
            meta: {
              title: "打扫卫生记录",
              keepAlive: false
            }
          },
        ]
      },     
    ]
  };
  
  export default routes;