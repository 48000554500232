// 接口统一管理
const api = {
    login: { 
        api: '/login/',  
        method: 'post' 
    },
    loginOut: {
        api: '/logout',
        method: 'get'
    },
    otherLogin:{
        api:'/login/jwt', 
        method:'post'
    },
    forgetPassword:{
        api:'/forgetPassword', 
        method:'post'
    },
    setNewPassword:{
        api:'/setNewPassword', 
        method:'post'
    },
    updatePassword:{
        api:'/updatePassword', 
        method:'post'
    },
    getImgCode: {
        api:'/kaptcha', 
        method:'get'
    },
    getCurrentInfo: {
        api: '/getCurrentUserInfo',
        method: 'get'
    },
    eleSigImport: '/lockerElectronicSignage/import',//导入电子标牌
    lockerEmployeeImport: '/lockerEmployee/import',//导入员工信息
    lockerEmployeeTemplateDownload: '/lockerEmployee/downloadTemplate',//员工模板下载
    eleSignTemplateDownload: '/lockerElectronicSignage/downloadTemplate',//电子标牌模板下载
    uploadResource: '/sysAttachment/upload',
    getImage: {
        api: '/sysAttachment/image',
        method: 'get'
    },
    getResource: {
        api: '/sysAttachment/findByNewSysAttachment',
        method: 'post'
    },
    //电子标牌列表
    lockerElectronicSignageList: {
        api: '/lockerElectronicSignage/list',
        method: 'post'
    },
    signUpdata: {
        api: '/lockerElectronicSignage/update',
        method: 'post'
    },
    //员工信息列表
    lockerEmployeeList: {
        api: '/lockerEmployee/list',
        method: 'post'
    },
    lockerUpdate: {
        api: '/lockerEmployee/update',
        method: "post"
    },
    //重新下发员工信息
    updateLockerEmployeeList: {
        api: '/lockerEmployee/redistributeData', 
        method: 'post'
    },
    //刷新水墨屏(员工)
    refreshEmployee: {
        api: '/lockerEmployee/refreshData', 
        method: 'post'
    },
    //重新下发电子标牌信息
    updateLockerElectronicSignage: {
        api: '/lockerElectronicSignage/redistributeData', 
        method: 'post'
    },
    //刷新水墨屏(电子标牌)
    refreshElectronicSignage: {
        api: '/lockerElectronicSignage/refreshData', 
        method: 'post'
    },
    //数据统计
    dataStatistics: {
        api: '/lockerUnlockingRecord/getStatistics',
        method: 'get'
    },
    //操作日志
    lockerOperationLog: {
        api: '/lockerOperationLog/list',
        method: 'post'
    },
    //操作日志详情
    lockerOperationLogDetail: {
        api: '/lockerOperationLogDetail/list',
        method: 'post'
    },
    //绑定记录
    lockerBindRecords: {
        api: '/lockerBindingRecords/list',
        method: 'post'
    },
    //绑定记录详情
    lockerBindDetail: {
        api: '/lockerBindingRecordsDetail/findBy',
        method: 'post'
    },
    //区域列表
    regionList: {
        api: '/lockerRegion/list',
        method: 'post'
    },
    //保存区域
    regionSaveOrEdit: {
        api: '/lockerRegion/save',
        method: 'post'
    },
    //删除区域
    deleteRegion: {
        api: '/lockerRegion/delete',
        method: 'delete'
    },
    //用户列表
    userList: {
        api: '/sysUser/list',
        method: 'post'
    },
    //保存用户
    userSaveOrEdit: {
        api: '/sysUser/save',
        method: 'post'
    },
    //删除用户
    deleteUser: {
        api: '/sysUser/delete',
        method: 'delete'
    },
    getRole: {
        api: '/sysUser/findBy',
        method: 'post'
    },
    getRegion: {
        api: '/lockerRegion/findBy',
        method: 'post'
    },
    //获取操作结果
    getOperationResults: {
        api: '/lockerOperationLog/getOperationResults',
        method: 'get'
    },
    //系统设置-查询定时任务状态
    getEduleStatus: {
        api: '/lockerCustomer',
        method: 'get'
    },
    //系统设置-保存定时任务状态
    enableScheduleSave: {
        api: '/lockerCustomer/updateScheduleStatus',
        method: 'post'
    }
}

export default api
