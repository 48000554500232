import API from "@/store/API/api";
import Axios from "@/store/API/axios";

const TaskManage = {
  // 获取任务总览
  getTaskOverviewServe({
    state
  }, {
    params
  }) {
    return Axios.get(`${API.getTaskOverview}/`, {params})
  },
  //获取设备类别
  getEquipmentCategoryServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getEquipmentCategory}/`, {params})
  },
  //获取设备活跃度
  getDeviceActivityServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getDeviceActivity}/`, {params})
  },
  //获取设备分布
  getEquipmentDistributionServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getEquipmentDistribution}/`, {params})
  },
  //获取代办事项
  getTodoServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getTodo}/`, {params})
  },
  //获取维保统计
  getMaintenanceStatisticsServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getMaintenanceStatistics}/`, {params})
  },
  //近6个月队伍作业统计
  getJobStatisticsServe({
    state
  }, {
    params
  }){
    return Axios.get(`${API.getJobStatistics}/`, {params})
  },
  // 获取任务明细
  getTaskDetailServe({
    state
  }, {
    tablePage,
    params
  }) {
    return Axios.post(`${API.getTaskDetail}/${tablePage.currentPage}/${tablePage.pageSize}/`, params)
  },
  // 任务明细曲线
  getTaskToCurveServe({
    state
  }, {
    tablePage,
    params
  }) {
    return Axios.post(`${API.getTaskToCurve}/`, params)
  },
}

export default TaskManage