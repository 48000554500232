const routes = {
  path: "/AgedEat",
  name: "AgedEat",
  meta: {
    title: "老年餐",
    keepAlive: false
  },
  redirect: {
    name: "AgedEat"
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: "AgedEat/workPerson",
      name: "operateDailys",
      component: () => import("@/views/Pages/agedEat/mechaismManage/operateDaily/list"),
      meta: {
        title: "用餐记录",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/dataCenter",
      name: "AgedEatdataCenter",
      component: () => import("@/views/Pages/agedEat/dataCenter/list"),
      meta: {
        title: "数据中心",
        keepAlive: false
      }
    },

    {
      path: "AgedEat/manManage/manManage",
      name: "manManage",
      component: () => import("@/views/Pages/agedEat/manManage/manManage/list"),
      meta: {
        title: "人员管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/manManage/manManage",
      name: "manManageDetail",
      component: () => import("@/views/Pages/agedEat/manManage/manManage/detail"),
      meta: {
        title: "人员管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/manManage/manManage/:type/:id?",
      name: "addPersonnelManage",
      component: () => import("@/views/Pages/agedEat/manManage/manManage/addOrEdit"),
      meta: {
        title: "操作人员管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/manManage/MealRecord",
      name: "MealRecord",
      component: () => import("@/views/Pages/agedEat/manManage/MealRecord/list"),
      meta: {
        title: "用餐记录",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/mechaismManage",
      name: "AgedEatmechaismManage",
      component: () => import("@/views/Pages/agedEat/mechaismManage/mechaismManage/list"),
      meta: {
        title: "机构管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/mechaismManage/:type/:id?",
      name: "addAgedEatmechaismManage",
      component: () => import("@/views/Pages/agedEat/mechaismManage/mechaismManage/addOrEdit"),
      meta: {
        title: "机构管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/mechaismManage/:type/:id?",
      name: "mechaismDetail",
      component: () => import("@/views/Pages/agedEat/mechaismManage/mechaismManage/detail"),
      meta: {
        title: "机构管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/workPerson",
      name: "operateDaily",
      component: () => import("@/views/Pages/agedEat/mechaismManage/operateDaily/list"),
      meta: {
        title: "运营日报",
        keepAlive: false
      }
    },

    {
      path: "AgedEat/evaluationAgency",
      name: "EvaluationAgency",
      component: () => import("@/views/Pages/agedEat/evaluationAgency/index"),
      meta: {
        title: "用餐记录",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/cardManage",
      name: "mealCard",
      component: () => import("@/views/Pages/agedEat/mealCard/cardManage/cardList"),
      meta: {
        title: "饭卡管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/addCard",
      name: "addCard",
      component: () => import("@/views/Pages/agedEat/mealCard/cardManage/addCard"),
      meta: {
        title: "饭卡管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/rechangeList",
      name: "rechangeList",
      component: () => import("@/views/Pages/agedEat/mealCard/rechargeCard/rechangeList"),
      meta: {
        title: "饭卡管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/addTrans",
      name: "addTrans",
      component: () => import("@/views/Pages/agedEat/mealCard/rechargeCard/addTrans"),
      meta: {
        title: "饭卡管理",
        keepAlive: false
      }
    },
    {
      path: "AgedEat/destoryCard",
      name: "destoryCard",
      component: () => import("@/views/Pages/agedEat/mealCard/rechargeCard/destoryCard"),
      meta: {
        title: "饭卡管理",
        keepAlive: false
      }
    },
  ]
};

export default routes;