import API from "@/store/API/api";
import Axios from "@/store/API/axios";
const AdaptationToAging = {
  // 适老化改造 获取施工单位列表
  getChgCompanyList({
      state
    },
    params
  ) {
    return Axios.post(`${API.getChgCompanyList}`, params)
  },
  // 适老化改造 修改或新增施工单位
  addOrSaveChgCompany({
      state
    },
    params
  ) {
    return Axios.post(`${API.addOrSaveChgCompany}`, params)
  },
  // 适老化改造 删除施工单位
  deleteChgCompany({
      state
    },
    params
  ) {
    return Axios.get(`${API.deleteChgCompany}/${params.id}`, params)
  },
  // 适老化改造 施工单位详情
  detailChgCompany({
      state
    },
    params
  ) {
    return Axios.get(`${API.detailChgCompany}/${params.id}`, params)
  },

  // 适老化改造 获取人员信息列表
  getChgPersonList({
      state
    },
    params
  ) {
    return Axios.post(`${API.getChgPersonList}`, params)
  },
  // 适老化改造 修改或新增人员信息
  addOrSaveChgPerson({
      state
    },
    params
  ) {
    return Axios.post(`${API.addOrSaveChgPerson}`, params)
  },
  // 适老化改造 删除人员信息
  deleteChgPerson({
      state
    },
    params
  ) {
    return Axios.get(`${API.deleteChgPerson}/${params.id}`, params)
  },
  // 适老化改造 人员信息详情
  detailChgPerson({
      state
    },
    params
  ) {
    return Axios.get(`${API.detailChgPerson}/${params.id}`, params)
  },
}
export default AdaptationToAging