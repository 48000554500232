const routes = {
  path: "/system",
  name: "System",
  meta: {
    title: "系统管理",
    keepAlive: false
  },
  redirect: {
    name: "System"
  },
  component: () => import('@/views/Layout/Main.vue'),
  children: [
    {
      path: "dictType",
      name: "DictType",
      component: () => import("@/views/Pages/System/DictType/list"),
      meta: {
        title: "字典类型",
        keepAlive: false
      }
    },
    {
      path: "dictType/add/:id?",
      name: "AddDictType",
      component: () => import("@/views/Pages/System/DictType/addOrEdit"),
      meta: {
        title: "人员管理",
        keepAlive: false
      }
    },
    {
      path: "dict",
      name: "Dict",
      component: () => import("@/views/Pages/System/Dict/list"),
      meta: {
        title: "数据字典",
        keepAlive: false
      }
    },
    {
      path: "dict/add/:id",
      name: "AddDict",
      component: () => import("@/views/Pages/System/Dict/addOrEdit"),
      meta: {
        title: "数据字典",
        keepAlive: false
      }
    },
    {
      path: "community",
      name: "Community",
      component: () => import("@/views/Pages/System/CommunityManagement/list"),
      meta: {
        title: "社区管理",
        keepAlive: false
      }
    },
    {
      path: "community/add/:id",
      name: "AddCommunity",
      component: () => import("@/views/Pages/System/CommunityManagement/addOrEdit"),
      meta: {
        title: "社区管理",
        keepAlive: false
      }
    },
    {
      path: "menu",
      name: "Menu",
      component: () => import("@/views/Pages/System/Menu/list"),
      meta: {
        title: "系统菜单",
        keepAlive: false
      }
    },
    {
      path: "userManage",
      name: "UserManage",
      component: () => import("@/views/Pages/System/User/list"),
      meta: {
        title: "用户管理",
        keepAlive: false
      }
    },
    {
      path: "addSystemUser",
      name: "AddSystemUser",
      component: () => import("@/views/Pages/System/User/addOrEdit"),
      meta: {
        title: "用户管理",
        keepAlive: false
      }
    },
    {
      path: "addSystemUser2",
      name: "AddSystemUser2",
      component: () => import("@/views/Pages/System/User/addOrEdit2"),
      meta: {
        title: "用户管理",
        keepAlive: false
      }
    },
    {
      path: "addSystemUser3",
      name: "AddSystemUser3",
      component: () => import("@/views/Pages/System/User/addOrEdit3"),
      meta: {
        title: "用户管理",
        keepAlive: false
      }
    },
    {
      path: "editSystemUser/:id?",
      name: "AditSystemUser",
      component: () => import("@/views/Pages/System/User/editUser"),
      meta: {
        title: "用户管理",
        keepAlive: false
      }
    },
    {
      path: "uploadFile",
      name: "UploadFile",
      component: () => import("@/views/Pages/System/UploadFile/list"),
      meta: {
        title: "文件管理",
        keepAlive: false
      }
    },
    {
      path: "log",
      name: "LogManage",
      component: () => import("@/views/Pages/System/Log/list"),
      meta: {
        title: "访问日志",
        keepAlive: false
      }
    },
    {
      path: "globalEvn",
      name: "GlobalEvn",
      component: () => import("@/views/Pages/System/GlobalEvn/list"),
      meta: {
        title: "抬头管理",
        keepAlive: false
      }
    },
    {
      path: "addGlobalEvn",
      name: "AddGlobalEvn",
      component: () => import("@/views/Pages/System/GlobalEvn/addOrEdit"),
      meta: {
        title: "抬头管理",
        keepAlive: false
      }
    },
  ]
};

export default routes;