const routes = {
    path: "/agedMarket",
    name: "agedMarket",
    meta: {
      title: "养老早市",
      keepAlive: false
    },
    redirect: {
      name: "agedMarket"
    },
    component: () => import('@/views/Layout/Main.vue'),
    children: [
      {
        path: "agedMarket/agedMarketData",
        name: "agedMarketPersonnelManage",
        component: () => import("@/views/Pages/agedMarket/agedMarketData/list"),
        meta: {
          title: "数据中心",
          keepAlive: false
        }
      },
      {
        path: "agedMarket/agedMarketData/:type/:id?",
        name: "agedMarketAddInfo",
        component: () => import("@/views/Pages/agedMarket/agedMarketData/addOrEdit"),
        meta: {
          title: "添加数据中心信息",
          keepAlive: false
        }
      },
      {
        path: "agedMarket/agedMarketList",
        name: "agedMarketServeList",
        component: () => import("@/views/Pages/agedMarket/agedMarketList/"),
        meta: {
          title: "机构列表",
          keepAlive: false
        }
      },
      {
        path: "agedMarket/addMktCompany/:type/:id?",
        name: "AddMktCompany",
        component: () => import("@/views/Pages/agedMarket/agedMarketList/addOrEdit"),
        meta: {
          title: "机构列表",
          keepAlive: false
        }
      },
      {
        path: "agedMarket/mktCompany/detail/:id?",
        name: "MktCompanyDetail",
        component: () => import("@/views/Pages/agedMarket/agedMarketList/detail"),
        meta: {
          title: "机构列表",
          keepAlive: false
        }
      },
      {
        path: "agedMarket/addMtkCoreSerive/:type/:id?",
        name: "AddMtkCoreSerive",
        component: () => import("@/views/Pages/agedMarket/agedMarketList/cnCoreSerive/addOrEdit"),
        meta: {
          title: "核心服务",
          keepAlive: false
        }
      },  
    ]
  };
  
  export default routes;