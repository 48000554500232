/*
 * @Author: your name
 * @Date: 2020-05-18 11:03:44
 * @LastEditTime: 2020-07-09 13:12:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hongtai-logistics\src\utils\tools.js
 */
import axios from "axios";

//日期转时间戳
const getDateTimes = function (date) {
  return new Date(date).getTime();
};
//日期转字符
const formateTime = function (datetime) {
  datetime = datetime
    .split('-').join('')
    .split(':').join('')
    .split(' ').join('');
  return datetime;
};
//字符转日期
const reversalTime = function (datetime) {
  if (datetime) {
    let year = datetime.slice(0, 4);
    let month = datetime.slice(4, 6);
    let day = datetime.slice(6, 8);
    let hh = datetime.slice(8, 10);
    let mm = datetime.slice(10, 12);
    let ss = datetime.slice(12, 14) || '00';
    return `${year}-${month}-${day} ${hh}:${mm}:${ss}`
  } else {
    return ''
  }
}
//获取当前日期的年月日时分秒
const formateDate = function (date) {
  let dateTime
  let yy = date.getFullYear()
  let mm = date.getMonth() + 1
  let dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  if (mm.toString().length == 1) {
      mm = "0" + mm;
  }
  dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
  return dateTime
}
//获取当前日期前几个月的日期
const getPreMonthDay = function (date, monthNum) {
  var dateArr = date.split('-');
  var year = dateArr[0]; //获取当前日期的年份
  var month = dateArr[1]; //获取当前日期的月份
  var day = dateArr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month) - monthNum;
  if (month2 <= 0) {
      var absM = Math.abs(month2);
      year2 = parseInt(year2) - Math.ceil(absM / 12 == 0 ? 1 : parseInt(absM) / 12);
      month2 = 12 - (absM % 12);
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
      day2 = days2;
  }
  if (month2 < 10) {
      month2 = '0' + month2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}
// 计算两个日期的时间差，精确到分
const timeDifference = function(time1, time2) {
  //判断开始时间是否大于结束日期
  if(time1>time2){
    alert("开始时间不能大于结束时间！");
    return false;
  }
  //截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
  var begin1=time1.substr(0,10).split("-");
  var end1=time2.substr(0,10).split("-");
  //将拆分的数组重新组合，并实例成化新的日期对象
  var date1=new Date(begin1[1] + - + begin1[2] + - + begin1[0]);
  var date2=new Date(end1[1] + - + end1[2] + - + end1[0]);
  //得到两个日期之间的差值m，以分钟为单位
  //Math.abs(date2-date1)计算出以毫秒为单位的差值
  //Math.abs(date2-date1)/1000得到以秒为单位的差值
  //Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
  var m=parseInt(Math.abs(date2-date1)/1000/60);
  //小时数和分钟数相加得到总的分钟数
  //time1.substr(11,2)截取字符串得到时间的小时数
  //parseInt(time1.substr(11,2))*60把小时数转化成为分钟
  var min1=parseInt(time1.substr(11,2))*60+parseInt(time1.substr(14,2));
  var min2=parseInt(time2.substr(11,2))*60+parseInt(time2.substr(14,2));
  //两个分钟数相减得到时间部分的差值，以分钟为单位
  var n=min2-min1;
  //将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
  var minutes=m+n;
  // 返回 分钟差
  return minutes;
}
// 输入出生年月日自动计算年龄
const getAge = function(strBirthday) {
  var returnAge,
   		strBirthdayArr=strBirthday.split("-"),
    	birthYear = strBirthdayArr[0],
    	birthMonth = strBirthdayArr[1],
    	birthDay = strBirthdayArr[2],  
   	 	d = new Date(),
    	nowYear = d.getFullYear(),
    	nowMonth = d.getMonth() + 1,
    	nowDay = d.getDate();   
    if(nowYear == birthYear){
        returnAge = 0;//同年 则为0周岁
    }
    else{
        var ageDiff = nowYear - birthYear ; //年之差
        if(ageDiff > 0){
            if(nowMonth == birthMonth) {
                var dayDiff = nowDay - birthDay;//日之差
                if(dayDiff < 0) {
                    returnAge = ageDiff - 1;
                }else {
                    returnAge = ageDiff;
                }
            }else {
                var monthDiff = nowMonth - birthMonth;//月之差
                if(monthDiff < 0) {
                    returnAge = ageDiff - 1;
                }
                else {
                    returnAge = ageDiff ;
                }
            }
        }else {
            returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
        }
    } 
    return returnAge;//返回周岁年
}
const creaElementA = function (url) {
  let link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
// 导出Excel公用方法
const exportMethod = function (data) {
  axios({
      method: data.method,
      data: data.params,
      url: data.url,
      responseType: 'blob',
      headers: {
        'Authorization': sessionStorage.token,
      },
  }).then((res) => {
      const link = document.createElement('a')
      let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)

      // link.download = res.headers['content-disposition'] //下载后文件名
      link.download = data.fileName //下载的文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
  }).catch(error => {
      this.$Notice.error({
          title: '错误',
          desc: '网络连接错误'
      })
      console.log(error)
  })
}
const exportFileMethod = function (data) {
  axios({
      method: data.method,
      data: data.params,
      url: data.url,
      responseType: 'blob',
      headers: {
        'Authorization': sessionStorage.token,
      },
  }).then((res) => {
      const link = document.createElement('a')
      let blob = new Blob([res.data], {type: 'application/octet-stream'})
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)

      // link.download = res.headers['content-disposition'] //下载后文件名
      let downloadTmpFileName = res.headers['content-disposition']
      if(downloadTmpFileName.indexOf("=") != -1){
        downloadTmpFileName = downloadTmpFileName.split("=")[1];
      }
      link.download = downloadTmpFileName
      // link.download = data.fileName //下载的文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
  }).catch(error => {
      this.$Notice.error({
          title: '错误',
          desc: '网络连接错误'
      })
      console.log(error)
  })
}
const tools = {
  getDateTimes,
  creaElementA,
  formateTime,
  reversalTime,
  formateDate,
  getPreMonthDay,
  exportMethod,
  exportFileMethod,
  getAge,
  timeDifference
};

export default tools;