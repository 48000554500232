//引入vxe-table
import Vue from "vue";
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/index.css";
Vue.use(VXETable)


VXETable.setup({
	icon: {
		// select
		SELECT_OPEN: 'ivu-icon el-icon-arrow-up ivu-select-arrow rotate180',
		SELECT_CLOSE: 'ivu-icon el-icon-arrow-down ivu-select-arrow',
	},
	grid: {
	  size: null,
	  // zoomConfig: {
	  //   escRestore: true
	  // },
	  // pagerConfig: {
	  //   perfect: false
	  // },
	  // toolbar: {
	  //   perfect: false
	  // },
	  // proxyConfig: {
	  //   autoLoad: true,
	  //   message: true,
	  //   props: {
	  //     list: null, // 用于列表，读取响应数据
	  //     result: 'result', // 用于分页，读取响应数据
	  //     total: 'page.total' // 用于分页，读取总条数
	  //   },
	  //   beforeItem: null,
	  //   beforeColumn: null,
	  //   beforeQuery: null,
	  //   afterQuery: null,
	  //   beforeDelete: null,
	  //   afterDelete: null,
	  //   beforeSave: null,
	  //   afterSave: null
	  // }
	},
	modal: {
		// size: null,
		minWidth: 340,
		minHeight: 200,
		lockView: true,
		mask: true,
		duration: 3000,
		marginSize: 5,
		dblclickZoom: true,
		showTitleOverflow: true,
		storage: false
	},
	input : {
		transfer: true,
	},
	select: {
		maxWidth: 500,
		size: null,
		transfer: true,
		multiCharOverflow: 8
	}
	// size: null, // 全局尺寸
	// zIndex: 100, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
	// version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
	// table: {
	//   showHeader: true,
	//   keepSource: false,
	//   animat: true,
	//   cloak: false,
	//   delayHover: 250,
	//   showOverflow: null,
	//   showHeaderOverflow: null,
	//   showFooterOverflow: null,
	//   size: null,
	//   resizable: false,
	//   stripe: false,
	//   border: false,
	//   round: false,
	//   radioConfig: {
	//     trigger: 'default'
	//   },
	//   checkboxConfig: {
	//     trigger: 'default'
	//   },
	//   sortConfig: {
	//     remote: false,
	//     trigger: 'default',
	//     orders: ['asc', 'desc', null]
	//   },
	//   filterConfig: {
	//     remote: false
	//   },
	//   expandConfig: {
	//     trigger: 'default'
	//   },
	//   treeConfig: {
	//     children: 'children',
	//     hasChild: 'hasChild',
	//     indent: 20
	//   },
	//   tooltipConfig: {
	//     theme: 'dark',
	//     enterable: false
	//   },
	//   editConfig: {
	//     mode: 'cell'
	//   },
	//   contextMenu: {
	//     visibleMethod () {}
	//   },
	//   rowId: '_XID', // 行数据的唯一主键字段名
	//   importConfig: {
	//     modes: ['insert', 'covering']
	//   },
	//   exportConfig: {
	//     isPrint: true,
	//     modes: ['current', 'selected']
	//   },
	//   customConfig: {
	//    storage: false
	//   },
	//   scrollX: {
	//     gt: 60
	//   },
	//   scrollY: {
	//     gt: 100
	//   }
	// },
	// grid: {
	//   size: null,
	//   zoomConfig: {
	//     escRestore: true
	//   },
	//   pagerConfig: {
	//     perfect: false
	//   },
	//   toolbar: {
	//     perfect: false
	//   },
	//   proxyConfig: {
	//     autoLoad: true,
	//     message: true,
	//     props: {
	//       list: null, // 用于列表，读取响应数据
	//       result: 'result', // 用于分页，读取响应数据
	//       total: 'page.total' // 用于分页，读取总条数
	//     }
	//     beforeItem: null,
	//     beforeColumn: null,
	//     beforeQuery: null,
	//     afterQuery: null,
	//     beforeDelete: null,
	//     afterDelete: null,
	//     beforeSave: null,
	//     afterSave: null
	//   }
	// },
	// pager: {
	//   size: null,
	//   autoHidden: false,
	//   perfect: true,
	//   pageSize: 10,
	//   pagerCount: 7,
	//   pageSizes: [10, 15, 20, 50, 100],
	//   layouts: ['PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total']
	// },
	// form: {
	//   size: null,
	//   colon: false
	// },
	// input: {
	//   size: null,
	//   transfer: false
	//   parseFormat: 'yyyy-MM-dd HH:mm:ss.SSS',
	//   labelFormat: '',
	//   valueFormat: '',
	//   startWeek: 1,
	//   digits: 2
	// },
	// textarea: {
	//   size: null
	//   autosize: {
	//     minRows: 1,
	//     maxRows: 10
	//   }
	// },
	// select: {
	//   size: null,
	//   transfer: false
	// },
	// toolbar: {
	//   size: null,
	//   import: {
	//     mode: 'covering'
	//   },
	//   export: {
	//     types: ['csv', 'html', 'xml', 'txt']
	//   },
	//   custom: {
	//     isFooter: true
	//   },
	//   buttons: []
	// },
	// button: {
	//   size: null,
	//   transfer: false
	// },
	// radio: {
	//   size: null
	// },
	// checkbox: {
	//   size: null
	// },
	// switch: {
	//   size: null
	// },
	// modal: {
	//   // size: null,
	//   minWidth: 340,
	//   minHeight: 200,
	//   lockView: true,
	//   mask: true,
	//   duration: 3000,
	//   marginSize: 5,
	//   dblclickZoom: true,
	//   showTitleOverflow: true
	//   storage: false
	// },
	// list: {
	//   scrollY: {
	//     gt: 100
	//   }
	// }


})

export default VXETable;